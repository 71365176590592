/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function MultipleSelection({resource, marginTop, label, handleChange, selected}) {

  useEffect(() => {
      loadData();
  },[]); 

  const [opcoes, setOpcoes] = useState([]);

  const handleClick = useCallback(async(data, event) => {
    handleChange(data.id, event.target.checked);
  },[]);

  const loadData = useCallback(async() => {
    try {
      
      const response = await api.get(`/${resource}`);
      setOpcoes(response.data);
    }
    catch(e){

    }
  },[]);

  const classes = useStyles();

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: marginTop}}>
        <FormLabel component="legend">{label}:</FormLabel>
        <FormGroup>
        {opcoes.map(data => {
          return (        
              <FormControlLabel key={data.id} control={<Checkbox checked={selected.includes(data.id)} 
                onChange={(event) => handleClick(data, event)} name={data.name} />} 
                label={data.name} />            
          )
        })}
        </FormGroup> 
    </FormControl>
  );
}
