import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Category from 'components/Category/Category.js';
import Subcategory from 'components/Subcategory/Subcategory.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function CouponsPage() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { addToast } = useToasts()
  const history = useHistory();

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true); 
      const response = await api.get("/coupons");      
      setLoading(false);
      setData(response.data);
    }
    catch(e){
      setLoading(false);
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        
    } 
  },[]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/coupons/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Cupons</h4>
            <p className={classes.cardCategoryWhite}>
              Cupons cadastrados no sistema.
            </p>
          </CardHeader>
          <CardBody> 
                
          <Link to="/admin/coupons/new"> <Button color="info" style={{marginTop: 20}}>Adicionar novo cupom</Button></Link>
 
          {data && data.length > 0 ? 
            <Table
                tableHeaderColor="primary"
                tableHead={['Codigo', 'Tipo', 'Valor', 'Valor a partir', '', '']}
                tableColumn={['code','type', 'value', 'value_start', 'edit', 'delete']}
                tableColumnSize={['20%', '30%', '30%', '20%', '1%', '1%']}
                tableData={data} 
                resource="coupons"            
                onDelete={(id) => handleDelete(id)}               
            /> : isLoading ? (
              <Box mt={6}>
               <CircularProgress />
              </Box>               
           ) : (           
             <p style={{marginTop: 20}}>Nenhum cupom cadastrado no momento.</p>
           )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
