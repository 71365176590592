import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function RatingsPage() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { addToast } = useToasts();
  const [loading, setLoading] = useState();

  const [ativo, setAtivo] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [ordem, setOrdem] = useState('mais_recentes');
  const [id, setId] = useState('');


  const [dataInicial, setDataInicial] = useState(new Date(new Date() - (60 * 86400000)));
  const [dataFinal, setDataFinal] = useState(new Date());

  const handleDataInicialChange = (date) => {
    setDataInicial(date);
  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const ratings = [];
      const response = await api.post("/ratings/search",{
        filtros: {
          name,
          email,
          ordem,
          dataInicial,
          dataFinal,
          id,
          ativo,
        }
      });
      setData(response.data);
      response.data.map(r => {

        ratings.push({
          id: r.id,
          cliente: r.customer.name,
          produto: r.product.nome,
          nota: r.nota,
          ativo: r.ativo ? 'Publicado' : 'Não publicado',
          created_at:  new Date(r.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
           })
        })
      })

      setData(ratings);
      setLoading(false);
    }
    catch(e){

      setLoading(false);
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        
    } 
  },[name, email, ordem, dataInicial, dataFinal, id, ativo]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/ratings/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Avaliações</h4>
            <p className={classes.cardCategoryWhite}>
              Avaliações realizadas pelos clientes.
            </p>
          </CardHeader>
          <CardBody>


          <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="name" 
            label="Pesquise pelo número do pedido"
            name="id"
            value={id}
            onChange={(e) => setId(e.target.value)}
       
         /> 

          <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="name" 
            label="Pesquise pelo nome do cliente"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
         
         />

        <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="name" 
            label="Pesquise pelo email do cliente"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          
         /> 

         
          <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 15}} label="Periodo inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
           </MuiPickersUtilsProvider>

           <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 25}} label="Periodo final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
           </MuiPickersUtilsProvider>

           <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 25}}>
            <InputLabel id="ordem_label">Publicado:</InputLabel>             
               <Select 
                    labelId="ativo"
                    label="publicado:"
                    variant="outlined"                  
                    name="ativo"
                    fullWidth
                    value={ativo}
                    onChange={(e) => setAtivo(e.target.value)}              
                    >                   
                      <MenuItem value={true}>
                        <em>Sim</em>
                      </MenuItem>
                      <MenuItem value={false}>
                        <em>Nao</em>
                      </MenuItem>
                                              
              </Select>
           </FormControl>
           
          <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 25}}>
            <InputLabel id="ordem_label">Ordenar por:</InputLabel>             
               <Select 
                    labelId="ordem"
                    label="ordenar por:"
                    variant="outlined"                  
                    name="ordem"
                    fullWidth
                    value={ordem}
                    onChange={(e) => setOrdem(e.target.value)}              
                    >                   
                      <MenuItem value={"mais_recentes"}>
                        <em>Mais recentes</em>
                      </MenuItem>
                      <MenuItem value={"mais_antigos"}>
                        <em>Mais antigos</em>
                      </MenuItem>
                                              
              </Select>
           </FormControl>


      

          <Button color="primary" type="button" style={{marginTop: 20}} onClick={getData}>Filtrar</Button>

          {loading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
            ) : ( 
             <>     
              {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['Nome', 'Produto', 'Nota', 'Ativo', 'Cadastrado em', '', '']}
                  tableColumn={['cliente', 'produto', 'nota', 'ativo', 'created_at', 'edit', 'delete']}
                  tableColumnSize={['20%', '20%', '20%', '10%', '10%', '1%', '1%']}
                  tableData={data} 
                  resource="ratings"            
                  onDelete={(id) => handleDelete(id)}
              
              /> : <p style={{marginTop: 20}}>Nenhum avaliação cadastrada no momento.</p>}
            </>
           )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
