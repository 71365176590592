import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

// core components

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditProduct() {
  const [action, setAction] = useState('create');
  const [isLoading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [type, setType] = useState('Valor');
  const [valor, setValor] = useState();
  const [posvenda, setPosVenda] = useState(false);
  const [valueStart, setValueStart] = useState();

  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    if (id != "new") {
      setAction("update");
    }
  }, []);

  useEffect(() => {
    if (action === "update") {
      loadData(id);
    }
    else {
      setLoaded(true);
      setLoading(false);
    }
  }, [action]);

  useEffect(() => {
  }, []);


  const handleSave = useCallback(async (e) => {

    try {

      e.preventDefault();

      const codigo = e.target.codigo.value;
      const valor = e.target.valor.value;
      const type = e.target.type.value;
      const valor_start = e.target.value_start.value;

      const value = valor.replace(",", ".");
      const value_start = valor_start.replace(",", ".");

      if (type === "Percentual" && value > 100) {
        addToast('Percentual deve ser até 100%', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;
      }

      if (type === "Valor" && value_start < 1) {
        addToast('Especifique o valor a partir de', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;

      }

      if (valueStart < value && type === "Valor") {
        addToast('Valores inválidos !', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;
      }

      const schema = Yup.object().shape({
        codigo: Yup.string().required('Código do produto é obrigatório'),
        valor: Yup.string().required('Informe o valor'),
      });

      await schema.validate({ codigo, valor, type }, {
        abortEarly: false,
      });

      const postData = {
        code: codigo,
        type,
        value,
        value_start,
        posvenda
      };


      if (action === 'update') {
        postData.id = id;
      }

      await api.post(`/coupons`, postData);

      history.push('/admin/coupons');

    }
    catch (e) {


      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/coupons");

    }
  }, [action, posvenda]);

  const loadData = useCallback(async () => {

    try {

      const response = await api.get(`/coupons/${id}`);
      setCodigo(response.data.code);
      setValor(response.data.value);
      setValueStart(response.data.value_start);
      setType(response.data.type);
      setPosVenda(response.data.posvenda);

      setLoaded(true);
      setLoading(false);

    }
    catch (e) {


      addToast('Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
      history.push("/admin/coupons");

    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados do cupom</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                {loaded ?
                  <>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="codigo"
                      label="Código"
                      name="codigo"
                      value={codigo}
                      required
                      onChange={(e) => setCodigo(e.target.value.toUpperCase())}
                    />


                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
                      <InputLabel id="type">Tipo</InputLabel>
                      <Select
                        labelId="type"
                        label="tipo"
                        variant="outlined"
                        name="type"
                        fullWidth
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <MenuItem key="Valor" value="Valor">Valor</MenuItem>
                        <MenuItem key="Percentual" value="Percentual">Percentual</MenuItem>
                      </Select>
                    </FormControl>

                    <CurrencyTextField
                      style={{ marginTop: 25 }}
                      label="Valor"
                      variant="standard"
                      value={valor}
                      currencySymbol=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      textAlign="left"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="valor"
                      onChange={(event, value) => setValor(value)}
                    />

                    <CurrencyTextField
                      style={{ marginTop: 18 }}
                      label="Desconto aplicado em compras a partir de"
                      variant="standard"
                      value={valueStart}
                      currencySymbol=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      textAlign="left"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="value_start"
                      onChange={(event, value) => setValueStart(value)}
                    />

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 18 }}>
                      <InputLabel id="type">Cupom ganho após venda para ser utilizado em até 30 dias?</InputLabel>
                      <Select
                        labelId="posvenda"
                        label="Cupom ganho após venda para ser utilizado em até 30 dias?"
                        variant="outlined"
                        name="posvenda"
                        fullWidth
                        value={posvenda}
                        onChange={(e) => setPosVenda(e.target.value)}
                      >
                        <MenuItem key={'S'} value={true}>Sim</MenuItem>
                        <MenuItem key={'N'} value={false}>Não</MenuItem>
                      </Select>
                    </FormControl>

                  </> : isLoading ? (
                    <Box mt={6}>
                      <CircularProgress />
                    </Box>
                  ) : null}

              </CardBody>
              {loaded ? <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter> : null}
            </form>

          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
