import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Category from 'components/Category/Category.js';
import Subcategory from 'components/Subcategory/Subcategory.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

// core components

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditConfiguracoesHome() {
  const [action, setAction] = useState('create');
  const [isLoading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [posicao, setPosicao] = useState(1);
  const [tipo, setTipo] = useState('categoria'); 
  const [category, setCategory] = useState('T');
  const [subcategory, setSubcategory] = useState('T');
  
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
    else{
      setLoaded(true);
      setLoading(false);
    }
  },[action]);

  useEffect(() => {      
  },[]);


  const handleSave = useCallback(async (e) => {

    try {  

      e.preventDefault();

      setLoading(true);

      const posicao = e.target.posicao.value;    
      const tipo = e.target.tipo.value;
     
   
      const schema = Yup.object().shape({
        posicao: Yup.string().required('Informe a posição'),
        tipo: Yup.string().required('Informe se é categoria ou subcategoria !'),       
       
      });        

      if (category === 'T'){
        addToast('Informe a categoria', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      if (tipo === 'subcategoria' && subcategory === 'T'){
        addToast('Informe a subcategoria', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      await schema.validate({posicao, tipo}, {
        abortEarly: false,
      });
           
      const postData = {          
        posicao,
        tipo,
        category_id: category,
        subcategory_id: subcategory != 'T' ? subcategory : ''
      };


      if (action === 'update'){
        postData.id = id;
      } 
 
      await api.post(`/opcaohome`, postData);
     
      history.push('/admin/home');
   
    }
    catch(e){ 

   
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
      setLoading(false);
  
    }
  },[action, category, subcategory, tipo, posicao]);

  const loadData = useCallback(async () => {   

    try {
      
      const response = await api.get(`/opcaohome/${id}`);
      setTipo(response.data.tipo);
      setPosicao(response.data.posicao);
      setCategory(response.data.category_id);
      setSubcategory(response.data.subcategory_id);         
      setLoaded(true);
      setLoading(false);
      
    }
    catch(e){
  

      addToast('Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      
      history.push("/admin/configuracoeshome");

    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
        
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Configuração Home - Categorias / Subcategorias</h4>
            </CardHeader>
            <CardBody>

            {loaded ?
            <>    

                 <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                  <InputLabel id="category">Posicao</InputLabel>             
                  <Select 
                          labelId="posicao"
                          label="posicao"
                          variant="outlined"                  
                          name="posicao"
                          fullWidth
                          value={posicao}
                          onChange={(e) => setPosicao(e.target.value)}              
                          >    
                          <MenuItem key="1" value={1}>1</MenuItem> 
                          <MenuItem key="2" value={2}>2</MenuItem>  
                          <MenuItem key="3" value={3}>3</MenuItem> 
                          <MenuItem key="4" value={4}>4</MenuItem>  
                          <MenuItem key="5" value={5}>5</MenuItem> 
                          <MenuItem key="6" value={6}>6</MenuItem>                                                                 
                  </Select>
               </FormControl> 


               <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                  <InputLabel id="category">Tipo</InputLabel>             
                  <Select 
                          labelId="tipo"
                          label="tipo"
                          variant="outlined"                  
                          name="tipo"
                          fullWidth
                          value={tipo}
                          onChange={(e) => setTipo(e.target.value)}              
                          >    
                          <MenuItem key="categoria" value="categoria">Categoria</MenuItem>
                          <MenuItem key="subcategoria" value="subcategoria">Subcategoria</MenuItem> 
                                                                                      
                  </Select>
               </FormControl> 
                         

                <Category value={category} handleChangeValue={(value) => setCategory(value)} marginTop={15} />

                {tipo === "subcategoria" ? <Subcategory value={subcategory} category={category} handleChangeValue={(value) => setSubcategory(value)} marginTop={22} /> : null}


                
                            
            </> : isLoading ? (
                <Box mt={6}>
                <CircularProgress />
                </Box>               
              ) : null}   
                
              </CardBody>
              {loaded ? <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter> : null}
           </form>

          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
