import axios from 'axios';
import { setCookie, parseCookies } from 'nookies'

  let isRefreshing = false;
  let failedRequestQueues = [];

  let cookies = parseCookies();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${cookies['chantillyrendas.token']}`
    }
  });

  api.interceptors.response.use(response => {
    return response;
  }, err => { 

      const originalConfig = err.config;
  
      if ( err.response.status === 401 ) {   

        if (err.response.data?.message === 'Invalid token') {              
           
            cookies = parseCookies();

            const { 'chantillyrendas.refreshtoken': refreshToken } = cookies;

            if (!isRefreshing) {
              
              isRefreshing = true   

              api.post('/refresh-token', {
                token: refreshToken,
              }).then(res => {  

                 if (res){

                  const { token } = res.data;

                  setCookie(undefined, 'chantillyrendas.token', token, {
                      maxAge: 60 * 60 * 24 * 30,
                      path: '/'
                  })

                  setCookie(undefined, 'chantillyrendas.refreshtoken', res.data.refresh_token, {
                    maxAge: 60 * 60 * 24 * 30,
                    path: '/'
                  })    
                  
                  
                  api.defaults.headers.Authorization = `Bearer ${token}`
                                  
                  failedRequestQueues.forEach(request => request.onSuccess(token))
                  failedRequestQueues = [];

               

                 }

              }).catch(err => {

                failedRequestQueues.forEach(request => request.onFailure(err))
                failedRequestQueues = [];
            
              }).finally(() => {

                isRefreshing = false;

              })

            }   
            
            return new Promise((resolve, reject) => {

              failedRequestQueues.push({
                  onSuccess: (token) => {    
                    originalConfig.headers.Authorization = `Bearer ${token}`
                    resolve(api(originalConfig))
                  },
                  onFailure: (err) => {
                    reject(err)
                  }
              })

            });
    
      }
       else {
           return Promise.reject(new AuthTokenError())            
        } 
   }
    return Promise.reject(err);    
});

export default api;
