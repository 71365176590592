import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AuthProvider } from './auth';
import { ToastProvider } from 'react-toast-notifications';
import useRouteName from './useRouteName';



const AppProvider = ({ children }) => (
  <AuthProvider>
    <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}      
        placement="top-center"
    >
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    {children}   
    </MuiPickersUtilsProvider>
    </ToastProvider>
  </AuthProvider>
);

export { AppProvider, useRouteName };
