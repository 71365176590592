import routesAdmin from '../routes/menu.routes';

const useRouteName = () => {
  let name = '';
  routesAdmin.forEach((route) => {
    if (window.location.href.indexOf(route.layout + route.path) !== -1) {
      name = route.name;
    }
  });
  return name;
};

export default useRouteName;
