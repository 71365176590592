import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { v4 as uuidV4 } from "uuid";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Table from 'components/Table/Table.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditEstoque() {
  const selectInputRef = useRef();
  const [action, setAction] = useState('create');
  const [operacao, setOperacao] = useState(1);
  const [quantidade, setQuantidade] = useState();
  const [codigo, setCodigo] = useState();
  const [produto, setProduto] = useState('');
  const [itens, setItens] = useState([]);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()
  const classes = useStyles();

  const getProduct = useCallback(async() => {
    setProduto('');
    const response = await api.post("/products/search", {
      filtros: {
       codigo_erp: codigo,
      }
    });

    if (response.data.length > 0){
      setProduto(`${response.data[0].nome} - ${response.data[0].images[0].color.name}`);
    }


  },[codigo]);

  const handleAdicionaItem = useCallback(async() => {
   
    if (!produto){
      addToast('Informe o produto', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    if (!quantidade){
      addToast('Informe a quantidade', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    let operacaoLabel = "";

    if (operacao === 1){
      operacaoLabel = "Adicionar";
    }
    else if (operacao === 2){
      operacaoLabel = "Remover";
    }
    else if (operacao === 3){
      operacaoLabel = "Substituir";
    }

    const jaInserido = itens.find(p => p.codigo === codigo);
  
    if (jaInserido){

      addToast('Item ja inserido na movimentação', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;

    }
    else{

      setItens([...itens, {
          id: uuidV4(),
          codigo: codigo,
          nome: produto,
          operacao,
          operacaoLabel,
          quantidade: quantidade.replace(",","."),     
      }]);  

      setProduto('');
      setCodigo('');
      setQuantidade('');
      setOperacao(1);
    }
    
  },[produto, quantidade, operacao]);
  

  const handleDeleteItem = useCallback(async(id) => {

    setItens(itens.filter(p => p.id !== id));

  },[itens]);

  const handleSave = useCallback(async () => {

    try { 
     
      if (itens.length === 0){
        addToast('Adicione ao menos um item para movimentação', {
          appearance: 'error',
          autoDismiss: true,
        });     
        return;
      }
     
      await api.post("/products/stock/itens", {
        itens
      });

      history.push('/admin/estoque');
   
    }
    catch(e){ 
  
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
            
      addToast('Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });     
  
    }
  },[action, itens]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Movimentar Estoque</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                <p><strong>Itens movimentação:</strong></p>  

                  {itens && itens.length === 0 ? <Box bgcolor="text.primary" color="warning.contrastText" p={2} style={{marginBottom: 20}}><p style={{color: '#FFF'}}>Nenhum produto selecionado.</p></Box> : null}

                  {itens && itens.length > 0 ? 
                      <>
                        <Table
                              tableHeaderColor="primary"
                              tableHead={['Produto', 'Operação', 'Quantidade', '']}
                              tableColumn={['nome', 'operacaoLabel', 'quantidade', 'delete']}
                              tableColumnSize={['50%', '15%', '15%', '1%']}
                              tableData={itens} 
                              onDelete={(id) => handleDeleteItem(id)}
                                          
                          /><br />
                      </> 
                  : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>                   
                     <TextField 
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="codigo"                     
                          label="Código ERP"
                          value={codigo}
                          onChange={(event) => setCodigo(event.target.value)}
                          onBlur={(event) => getProduct()}
                      />           
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>                   
                     <TextField 
                          variant="outlined"
                          margin="normal"
                          disabled 
                          required
                          fullWidth
                          id="produto"                     
                          label="Produto"
                          value={produto}
                      />           
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}> 

                  <CurrencyTextField
                    style={{marginTop: 20}}
                      label="Quantidade"
                      variant="standard"  
                      value={quantidade}                  
                      currencySymbol=""
                      variant="outlined"
                      margin="normal"       
                      fullWidth
                      textAlign="left"              
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="quantidade"
                      onChange={(event, value) => setQuantidade(value)}
                  />

                

                   </GridItem>               

                   <GridItem xs={12} sm={12} md={2}>  
                   <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 18}}>
                   <InputLabel id="operacap">Tipo Operação</InputLabel>  
                    <Select labelId="operacao"
                        label="Tipo Operação"
                        variant="outlined"                  
                        name="operacao"
                        fullWidth
                        value={operacao}
                        onChange={(e) => setOperacao(e.target.value)}              
                        >    
                        <MenuItem key="A1" value={1}>Adicionar</MenuItem> 
                        <MenuItem key="A2" value={2}>Remover</MenuItem> 
                        <MenuItem key="A3" value={3}>Substituir</MenuItem>                                                               
                     </Select>
                     </FormControl>
                    
                   </GridItem>               

                   <GridItem xs={12} sm={12} md={2}>  
                       <Button color="info" type="button" style={{marginTop: 22}} onClick={() => handleAdicionaItem()}>ADICIONAR</Button>                    
                   
                   </GridItem>               

                   
              </GridContainer>
             
            </CardBody>
            <CardFooter>
              <Button color="primary" type="button" onClick={() => handleSave()}>Registrar movimentação</Button>
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
