import React, {useRef, useState} from 'react'

const FileUploader = ({onFileSelectSuccess, onFileSelectError}) => {

    const [file, setFile] = useState();

    const fileInput = useRef(null)

    const handleFileInput = (e) => {          
      const file = e.target.files[0];

      if (file.size > (8 * 1024 * 1024)) {
         onFileSelectError({ error: "Arquivo não dever maior que 8 Mb" });
      }
      else {
          setFile(URL.createObjectURL(file));
          onFileSelectSuccess(file);
      }
    };

    return (
        <div className="file-uploader">            
            <input type="file" onChange={handleFileInput} />
        </div>
    )
}

export default FileUploader;