import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = {
    toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
}; 
   
// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function Configurations() {

  const configurationID = '098b1f16-0b1e-4073-9772-1acb31167767';
  const [action, setAction] = useState('create');
  const [loaded, setLoaded] = useState(false);
  const [telefonewhatsapp, setTelefoneWhatsapp] = useState();
  const [telefonecontato01, setTelefoneContato01] = useState();
  const [telefonecontato02, setTelefoneContato02] = useState();
  const [telefonecontato03, setTelefoneContato03] = useState();
  const [localizacao, setLocalizacao] = useState();
  const [emailcontato, setEmailContato] = useState();
  const [noparcelas, setNoParcelas] = useState(1);
  const [valorminparcela, setValorMinParcela] = useState();
  const [valordesconto, setValorDesconto] = useState();
  
  const [valorFreteGratis, setValorFreteGratis] = useState('0,00');
  const [valorMinimoCompra, setValorMinimoCompra] = useState('0,00');
  const [isLoading, setLoading] = useState(true);
  const [qtdediasAvaliacao, setQtdeDiasAvaliacao] = useState('');
  const [qtdediasCarrinhoPerdido, setQtdeDiasCarrinhoPerdido] = useState('');
  const [textoConfirmacaoPedido, setTextoConfirmacaoPedido] = useState('');
  const [textoMudancaStatus, setTextoMudancaStatus] = useState('');
  const [textoCarrinhoPerdido, setTextoCarrinhoPerdido] = useState('');
  

  const [text, setText] = useState('');
  const formRef = useRef(null);
  const { addToast } = useToasts()
  const classes = useStyles();

  useEffect(() => {
   
  },[]);

  useEffect(() => {   
      loadData();
   
  },[]);

  const handleSave = async (e) => {

    try { 

      e.preventDefault();
     
      const parcelas = parseInt(e.target.noparcelas.value);
      const valormin = e.target.valorminparcela.value;
      const desconto = parseFloat(e.target.valordesconto.value);

      if (desconto > 100 || desconto < 0){
        addToast('Valor de desconto % inválido', {
          appearance: 'error',
          autoDismiss: true,
        });  
        return;
      }

      

      const txt_confirmacaopedido = textoConfirmacaoPedido;
      const txt_carrinhoperdido = textoCarrinhoPerdido;
      const txt_mudancastatus = textoMudancaStatus;
      const qtdedias_avaliacao = e.target.qtdediasAvaliacao.value;
      const qtdedias_carrinhoperdido = e.target.qtdediasCarrinhoPerdido.value;
      const id = configurationID;

      const schema = Yup.object().shape({
        parcelas: Yup.number()
        .typeError('Informe um número de parcelas válido')
        .required('Informe o número de parcelas máxima para parcelamento no cartão de crédito')
        .min(1, 'Mínimo de 1 parcela'), 
        qtdedias_avaliacao: Yup.number()
        .typeError('Informe um número de dias válido')
        .required('Informe a qtde dias para envio da avaliação do pedido')
        .min(1, 'Mínimo de 1 para o campo número de dias para avaliação'),       
        qtdedias_carrinhoperdido: Yup.number()
        .typeError('Informe um número de dias válido')
        .required('Informe a qtde dias para notificação de carrinho perdido')
        .min(1, 'Mínimo de 1 para o campo número de dias para notificação de carrinho perdido'),       
      });

      await schema.validate({qtdedias_avaliacao, qtdedias_carrinhoperdido, parcelas}, {
        abortEarly: false,
      });

    
      const postData = {  
        id,
        txt_confirmacaopedido,
        txt_carrinhoperdido,
        txt_mudancastatus,
        qtdedias_avaliacao,
        qtdedias_carrinhoperdido,
        valor_frete_gratis: valorFreteGratis.replace(",","."),
        valor_minimo_compra: valorMinimoCompra.replace(",","."),
        telefonewhatsapp,
        telefonecontato01,
        telefonecontato02,
        telefonecontato03,
        localizacao,
        emailcontato,
        noparcelas: parcelas,
        valorminparcela: valormin.replace(",","."),
        descontoavista: desconto,
      };

     
      await api.post(`/configurations`, postData);  
      

      addToast('Dados atualizados com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });   
   
    }
    catch(e){ 

  

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });         
  
   }
  }


  const loadData = useCallback(async () => {    
    try {
      
      setLoading(true);
      const response = await api.get(`/configurations/${configurationID}`);
      setTextoCarrinhoPerdido(response.data.txt_carrinhoperdido); 
      setTextoConfirmacaoPedido(response.data.txt_confirmacaopedido);
      setTextoMudancaStatus(response.data.txt_mudancastatus);
      setQtdeDiasAvaliacao(response.data.qtdedias_avaliacao);
      setQtdeDiasCarrinhoPerdido(response.data.qtdedias_carrinhoperdido);
      setValorFreteGratis(response.data.valor_frete_gratis);
      setEmailContato(response.data.emailcontato);
      setTelefoneContato01(response.data.telefonecontato01);
      setTelefoneContato02(response.data.telefonecontato02);
      setTelefoneContato03(response.data.telefonecontato03);
      setTelefoneWhatsapp(response.data.telefonewhatsapp);
      setLocalizacao(response.data.localizacao);
      setValorMinimoCompra(response.data.valor_minimo_compra);
      setNoParcelas(response.data.noparcelas);
      setValorMinParcela(response.data.valorminparcela);
      setValorDesconto(response.data.descontoavista);
      setLoaded(true);
      setLoading(false);      
     
    }
    catch(e){ 




      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });             
      
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>           
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Configurações gerais</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>

             {loaded ? <div>   
           
              <GridContainer>

              <GridItem xs={12} sm={12} md={12}>          
                <InputMask mask="(99) 999999999" value={telefonewhatsapp} onChange={(event) => setTelefoneWhatsapp(event.target.value)}>
                  <TextField 
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="telefonewhatsapp"                     
                        label="Telefone Whatsapp"
                   />
                 </InputMask>
              </GridItem>     
      

              <GridItem xs={12} sm={12} md={12}>
                <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        id="emailcontato"                      
                        label="Email Contato"
                        name="emailcontato"                     
                        value={emailcontato}
                        onChange={(event) => setEmailContato(event.target.value)}                      
                  />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
              <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="text"
                      id="localizacao"                      
                      label="Localização"
                      name="localizacao"                     
                      value={localizacao}
                      onChange={(event) => setLocalizacao(event.target.value)}
                     
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>

              <InputMask mask="(99) 999999999" value={telefonecontato01} onChange={(event) => setTelefoneContato01(event.target.value)}>
                  <TextField 
                        variant="outlined"
                        margin="normal"                        
                        type="text"
                        id="telefonecontato01"                      
                        label="Telefone Contato 01"
                        name="telefonecontato01" 
                        fullWidth />
                 </InputMask>

              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
              <InputMask mask="(99) 999999999" value={telefonecontato02} onChange={(event) => setTelefoneContato02(event.target.value)}>
               <TextField
                      variant="outlined"
                      margin="normal"                    
                      fullWidth
                      type="text"
                      id="telefonecontato02"                      
                      label="Telefone Contato 02"
                      name="telefonecontato02"                    
                                        
                />
              </InputMask>  
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
              <InputMask mask="(99) 999999999" value={telefonecontato03} onChange={(event) => setTelefoneContato03(event.target.value)}>
               <TextField
                      variant="outlined"
                      margin="normal"                      
                      fullWidth
                      type="text"
                      id="telefonecontato03"                      
                      label="Telefone Contato 03"
                      name="telefonecontato03"                     
                                      
                />
              </InputMask>  
              </GridItem>


             <GridItem xs={12} sm={12} md={12}>

              <CurrencyTextField
                  style={{marginTop: 25}} 
                  label="Valor minimo de compra"
                  variant="standard"
                  value={valorMinimoCompra}
                  currencySymbol="R$"
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valorMinimoCompra"
                  onChange={(event, value)=> setValorMinimoCompra(value)}
               />

              </GridItem>

              <GridItem xs={12} sm={12} md={12}>


              <CurrencyTextField
                  style={{marginTop: 25}} 
                  label="Frete gratis a partir de R$"
                  variant="standard"
                  value={valorFreteGratis}
                  currencySymbol="R$"
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valorFreteGratis"
                  onChange={(event, value)=> setValorFreteGratis(value)}
               />
               
                </GridItem>               

                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      type="number"
                      label="Qtde dias notificação para avaliação do pedido"
                      name="qtdediasAvaliacao"                     
                      value={qtdediasAvaliacao}
                      onChange={(e) => setQtdeDiasAvaliacao(e.target.value)}                      
                  />
                </GridItem>               
              </GridContainer> 
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      type="number"
                      label="Qtde dias notificação carrinho perdido"
                      name="qtdediasCarrinhoPerdido"                     
                      value={qtdediasCarrinhoPerdido}
                      onChange={(e) => setQtdeDiasCarrinhoPerdido(e.target.value)}
                     
                  />
                </GridItem>               
              </GridContainer>             

               <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="noparcelas"
                      type="number"
                      label="Quantidade numero parcelas máximo - Cartão de credito"
                      name="noparcelas"                     
                      value={noparcelas}
                      onChange={(e) => setNoParcelas(e.target.value)}
                     
                  />
                </GridItem>               
              </GridContainer>         

              <GridContainer>
               <GridItem xs={12} sm={12} md={12}>

              <CurrencyTextField
                  style={{marginTop: 25}} 
                  required 
                  label="Valor mimino da parcela - Cartão de crédito"
                  variant="standard"
                  value={valorminparcela}
                  currencySymbol="R$"
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valorminparcela"
                  onChange={(event, value)=> setValorMinParcela(value)}
               />
               
                </GridItem> 
                </GridContainer>

                 <GridContainer>             

                 <GridItem xs={12} sm={12} md={12}>

              <CurrencyTextField
                  style={{marginTop: 25}} 

                  label="Valor % desconto a vista - Boleto bancário"
                  variant="standard"
                  value={valordesconto}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valordesconto"
                  onChange={(event, value)=> setValorDesconto(value)}
               />
               
                </GridItem>      
                </GridContainer>               

              <br />
              <h4>Texto email confirmação do pedido</h4>
              <CKEditor
                    editor={ Editor }
                    config={ editorConfiguration }
                    data={textoConfirmacaoPedido}
                    onReady={(editor) => {       
                      editor.editing.view.change((writer) => {
                      writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                      );
                      });
                    }}    
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      setTextoConfirmacaoPedido(data);
                  } }           
              />     
              <br />
              <h4>Texto email mudança status do pedido</h4>
              <CKEditor
                    editor={ Editor }
                    config={ editorConfiguration }
                    data={textoMudancaStatus}
                    onReady={(editor) => {       
                      editor.editing.view.change((writer) => {
                      writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                      );
                      });
                    }}    
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();              
                      setTextoMudancaStatus(data);
                  } }           
              />     

              <br />
              <h4>Texto email carrinho de compras perdido - em andamento</h4>
              <CKEditor
                    editor={ Editor }
                    config={ editorConfiguration }
                    data={textoCarrinhoPerdido}
                    onReady={(editor) => {       
                      editor.editing.view.change((writer) => {
                      writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                      );
                      });
                    }}    
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      setTextoCarrinhoPerdido(data);
                  } }           
              />     
              
              </div> : isLoading ? (
                <Box mt={6}>
                <CircularProgress />
                </Box>               
              ) : null}
                    
            </CardBody>
            <CardFooter>
              {loaded ? <Button color="primary" type="submit">Salvar</Button> : null}
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
      
    </div>
  );
}
