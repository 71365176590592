// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Stars from '@material-ui/icons/Stars';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import Build from '@material-ui/icons/Build';
import Loyalty from '@material-ui/icons/Loyalty';

// core components/views for Admin layout
import AdminPage from '../pages/Admin';
import DashboardPage from '../pages/Dashboard';
import CategoriesPage from '../pages/Categories';
import SubcategoriesPage from '../pages/Subcategories';
import TextsPage from '../pages/Texts';
import CustomersPage from '../pages/Customers';
import OrdersPage from '../pages/Orders';
import RatingsPage from '../pages/Ratings';
import ProductsPage from '../pages/Products';
import BannersPage from '../pages/Banners';
import ConfigurationsPage from '../pages/Configurations';
import ProductsCompositionPage from '../pages/ProductsComposition';
import ProductsColorPage from '../pages/ProductsColor';

import CouponsPage from '../pages/Coupons';
import PrecosPage from '../pages/Precos';
import ConfiguracoesHomePage from '../pages/ConfiguracoesHome';
import RendimentosPage from '../pages/Rendimentos';
import LargurasPage from '../pages/Larguras';
import EstoquePage from '../pages/Estoque';

const menuRoutes = [
  {
    path: '/painel',
    name: 'Painel',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin'
  },
  {
    path: '/administradores',
    name: 'Administradores',
    icon: Person,
    component: AdminPage,
    layout: '/admin',
  },
  {
    path: '/customers',
    name: 'Clientes',
    icon: Person,
    component: CustomersPage,
    layout: '/admin',
  },
  {
    path: '/coupons',
    name: 'Cupons',
    icon: Loyalty,
    component: CouponsPage,
    layout: '/admin',
  },
  {
    path: '/orders',
    name: 'Pedidos',
    icon: ShoppingCart,
    component: OrdersPage,
    layout: '/admin',
  },
  { 
    path: '/ratings',
    name: 'Avaliações',
    icon: Stars,
    component: RatingsPage,
    layout: '/admin',
  }, 
  {
    path: '/compositions',
    name: 'Composições',
    icon: Assignment,
    component: ProductsCompositionPage,
    layout: '/admin',
  },
  {
    path: '/colors',
    name: 'Cores',
    icon: Assignment,
    component: ProductsColorPage,
    layout: '/admin',
  },
  {
    path: '/categories',
    name: 'Categorias',
    icon: Assignment,
    component: CategoriesPage,
    layout: '/admin',
  },
  {
    path: '/subcategories',
    name: 'Subcategorias',
    icon: Assignment,
    component: SubcategoriesPage,
    layout: '/admin',
  },
  {
    path: '/products',
    name: 'Produtos',
    icon: Loyalty,
    component: ProductsPage,
    layout: '/admin',
  },
  {
    path: '/estoque',
    name: 'Estoque',
    icon:  Assignment,
    component: EstoquePage,
    layout: '/admin',
  }, 
  {
    path: '/banners',
    name: 'Banners Site',
    icon: ViewCarousel,
    component: BannersPage,
    layout: '/admin',
  },

  {
    path: '/texts',
    name: 'Textos Site',
    icon: Assignment,
    component: TextsPage,
    layout: '/admin',
  },

  {
    path: '/configurations',
    name: 'Configuracoes Gerais',
    icon: Build,
    component: ConfigurationsPage,
    layout: '/admin',
  },
  {
    path: '/precos',
    name: 'Conf. Preços',
    icon: Build,
    component: PrecosPage,
    layout: '/admin',
  },
  {
    path: '/rendimentos',
    name: 'Conf. Rendimentos',
    icon: Build,
    component: RendimentosPage,
    layout: '/admin',
  },
  {
    path: '/larguras',
    name: 'Conf. Larguras',
    icon: Build,
    component: LargurasPage,
    layout: '/admin',
  },
  {
    path: '/home',
    name: 'Conf. Home',
    icon: Build,
    component: ConfiguracoesHomePage,
    layout: '/admin',
  },

  
];

export default menuRoutes;
