import React, {
  createContext, useCallback, useState, useContext,
} from 'react';
import api from '../services/api';
import { setCookie, parseCookies, destroyCookie } from 'nookies'

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {

    let cookies = parseCookies();

    const { 'chantillyrendas.token': token } = cookies;
    const { 'chantillyrendas.user': user } = cookies;

    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }
    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
      admin: 1
    });  

    const { token, user, refresh_token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setCookie(undefined, 'chantillyrendas.token', token, {
      maxAge: 60 * 60 * 24 * 30,
      path: '/'
    })

    setCookie(undefined, 'chantillyrendas.refreshtoken', refresh_token, {
      maxAge: 60 * 60 * 24 * 30,
      path: '/'
    })

    setCookie(undefined, 'chantillyrendas.user', JSON.stringify(user), {
      maxAge: 60 * 60 * 24 * 30,
      path: '/'
    })
  

    setData({ token, user });

  }, []);

  const signOut = useCallback(() => {
        
    destroyCookie(undefined, 'chantillyrendas.token')
    destroyCookie(undefined, 'chantillyrendas.refreshtoken')
    destroyCookie(undefined, 'chantillyrendas.user')

    setData({});
  }, []);

  const updateUser = useCallback(
    (user) => {
      setData({
        token: data.token,
        user,
      });

      setCookie(undefined, 'chantillyrendas.user', JSON.stringify(user), {
        maxAge: 60 * 60 * 24 * 30,
        path: '/'
      });

    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user, signIn, signOut, updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
