import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import Category from 'components/Category/Category.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Subcategories() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('T');
  const { addToast } = useToasts()

  useEffect(() => {
      getData();
      loadCategories();
  }, []);

  
  const getData = useCallback(async () => {
    try {

      setLoading(true); 
      const subcategories = [];
   
      const response = await api.get("/subcategories", {
        params: {
          category_id: category != "T" ? category : null
        }
      });

      if (response.data){
        response.data.map(sub => {
          subcategories.push({
            id: sub.id,
            name: sub.name,
            category: sub.category?.name
          })
        })
      }  

      setData(subcategories);
      setLoading(false);

    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });   
      setLoading(false);     
    } 
  },[category]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',
          onClick: () => alert('Click No')
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/subcategories/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  const loadCategories = useCallback(async() => {
    try {
      const response = await api.get("/categories");
      setCategories(response.data);
    }
    catch(e){

    }
  },[]);

  
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Subcategorias</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de subcategorias cadastradas no sistema.
            </p>
          </CardHeader>
          <CardBody>

          <Category value={category} handleChangeValue={(value) => setCategory(value)} marginTop={10} />
          
          
           <Button color="primary" type="button" style={{marginTop: 20}} onClick={getData}>Filtrar</Button>
          
           <Link to="/admin/subcategories/new"> <Button color="info" style={{marginTop: 20}}>Adicionar nova subcategoria</Button></Link>
        
           {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Nome', 'Categoria', '', '']}
              tableColumn={['name',  'category', 'edit', 'delete']}
              tableColumnSize={['40%', '40%', '1%', '1%']}
              tableData={data} 
              resource="subcategories"            
              onDelete={(id) => handleDelete(id)}
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhum subcategoria cadastrada no momento.</p>
          )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
