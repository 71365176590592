/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';
import EditCategory from "pages/Categories/edit";

const useStyles = makeStyles(styles);

export default function Category({value, handleChangeValue, marginTop}) {

  useEffect(() => {
       loadCategories();
  },[]); 

  const [categories, setCategories] = useState([]);

  const loadCategories = useCallback(async() => {
    try {
      const response = await api.get("/categories");
      setCategories(response.data);
    }
    catch(e){

    }
  },[]);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: marginTop}}>
    <InputLabel id="category">Categoria</InputLabel> 
         
       <Select 
            labelId="category"
            label="categoria"
            variant="outlined"                  
            name="category"
            fullWidth
            value={value ? value : 'T'}
            onChange={(e) => handleChangeValue(e.target.value)}              
            >    
            <MenuItem key="T" value="T">Selecione</MenuItem>                                   
            {categories.map(category => {
               return <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>        
            })}                               
      </Select>
   </FormControl>
  );
}
