import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';

import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, TextareaAutosize } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditRating() {
  const [action, setAction] = useState('create');
  const [ativo, setAtivo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    loadData(id);
  },[]);
  

  const loadData = useCallback(async () => {    
    try {
      setLoading(true);
  
      const response = await api.get(`/ratings/${id}/get`);
      setData(response.data); 
      setAtivo(response.data.ativo);

      setLoading(false);
      

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/customers");
    }
  });


  const updateStatus = useCallback(async (status) => {    
    try {
    
  
      await api.patch(`/ratings`,{
        id,
        ativo: status
      });

      addToast('Atualizado com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });       

      setAtivo(status);     
      

    }
    catch(e){

      setLoading(false);
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>         
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da avaliação</h4>            
            </CardHeader>
            <CardBody>

                {loading ? (
                  <Box mt={6}>
                  <CircularProgress />
                  </Box>               
                ) : (      
                    <>     
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Nome"
                            name="name"
                            disabled 
                            value={data.customer.name}                     
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer> 

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            disabled 
                            value={data.customer.email}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="telefone1"
                            label="Telefone 1"
                            name="telefone"
                            disabled 
                            value={data.customer.tel_01}                      
                            autoFocus
                        />
                      </GridItem>               
                      <GridItem xs={12} sm={4} md={4}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="telefone1"
                            label="Telefone 2"
                            name="telefone"
                            disabled 
                            value={data.customer.tel_02}                      
                            autoFocus
                        />
                      </GridItem>               
                      <GridItem xs={12} sm={4} md={4}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="telefone1"
                            label="Telefone 3"
                            name="telefone"
                            disabled 
                            value={data.customer.tel_03}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="produto"
                            label="Produto"
                            name="produto"
                            disabled 
                            value={data.product.nome}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="nota"
                            label="Nota"
                            name="nota"
                            disabled 
                            value={data.nota}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="nopedido"
                            label="No pedido"
                            name="nopedido"
                            disabled 
                            value={data.order_id}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>
                
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="cadastrado"
                            label="Cadastrado em"
                            name="cadastrado"
                            disabled 
                            value={new Date(data.created_at).toLocaleDateString('pt-br', {
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric'
                             })}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 25}}>
                      <InputLabel id="ordem_label">Publicado:</InputLabel>             
                        <Select 
                              labelId="ativo"
                              label="publicado:"
                              variant="outlined"                  
                              name="ativo"
                              fullWidth
                              value={ativo}
                              onChange={(e) => updateStatus(e.target.value)}>                   
                                <MenuItem value={true}>
                                  <em>Sim</em>
                                </MenuItem>
                                <MenuItem value={false}>
                                  <em>Nao</em>
                                </MenuItem>
                                                        
                        </Select>
                    </FormControl>


                    <br /><hr />

                    <h4>Observações:</h4>

                    <p>{data.obs}</p>

                   
                   
                    </>
                   )}
                                
            </CardBody>
            <CardFooter>
            <Link to="/admin/ratings"><Button color="primary" type="button">Voltar</Button></Link>
            </CardFooter>
          
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
