import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = { 
    toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
}; 

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

// core components

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Category from 'components/Category/Category.js';
import Subcategory from 'components/Subcategory/Subcategory.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import MultipleSelection from 'components/MultipleSelection/MultipleSelection.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditProduct() {
  const [action, setAction] = useState('create');
  const [isLoading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [unidade, setUnidade] = useState('kg');
  const [video_url, setVideoUrl] = useState('');
  const [largura, setLargura] = useState('0.00');
  const [altura, setAltura] = useState('0.00');
  const [peso, setPeso] = useState('0.00');
  const [comprimento, setComprimento] = useState('0.00');
  const [valor, setValor] = useState();
  const [rendimento_kg, setRendimentoKg] = useState();
  const [valor_metro_kg, setValorMetroKg] = useState('0.00');
  const [codigo_erp, setCodigoErp] = useState('');

  const [codigo_ncm, setCodigoNcm] = useState('');
  //const [codigo_extipi, setCodigoExtIpi] = useState('');
  //const [origem, setOrigem] = useState('');

  const [colors, setColors] = useState([]);
  const [compositions, setCompositions] = useState([]);
  
  const [nome, setNome] = useState(''); 
  const [descricao, setDescricao] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [destaque, setDestaque] = useState(false);  
  const [category, setCategory] = useState('T');
  const [subcategory, setSubcategory] = useState('T');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (valor && rendimento_kg){
      if (valor > 0 && rendimento_kg > 0){
        const total = valor / rendimento_kg;
        setValorMetroKg(total.toFixed(2));
      }   
      
    }

  },[valor, rendimento_kg])

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
    else{
      setLoaded(true);
      setLoading(false);
    }
  },[action]);

  useEffect(() => {      
  },[colors]);

  const handleChangeColor = useCallback(async(id, checked) => {
    
    if (checked){
      setColors(colors => [...colors, id]);
    }
    else{ 
      setColors(colors => colors.filter(c => c !== id));
    }

  },[colors]);

  const handleChangeComposition = useCallback(async(id, checked) => {

    if (checked){
      setCompositions(compositions => [...compositions, id]);
    }
    else{ 
      setCompositions(compositions => compositions.filter(c => c !== id));
    }

  },[compositions]);

  const handleSave = useCallback(async (e) => {

    try {  

      e.preventDefault();

      const codigo = e.target.codigo.value;
      const nome = e.target.nome.value;
      const category_id = category !== 'T' ? category : '';
      const subcategory_id = subcategory !== 'T' ? subcategory : '';
      const largura = e.target.largura.value;
      const altura = e.target.altura.value;
      const peso = e.target.peso ? e.target.peso.value : '0.00';
      const comprimento = e.target.comprimento ? e.target.comprimento.value : '0.00';
      const rendimento_kg = e.target.rendimento_kg ? e.target.rendimento_kg.value : 0;
      const valor = e.target.valor.value;
      const video_url = e.target.video_url.value;

      if (!subcategory){
        addToast('Informe a subcategoria', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

     
      const schema = Yup.object().shape({
        codigo: Yup.string().required('Código do produto é obrigatório'),      
        nome: Yup.string().required('Nome do produto é obrigatório'),
        category_id: Yup.string().required('Informe a categoria'),
        subcategory_id: Yup.string().required('Informe a subcategoria'),
        descricao: Yup.string().required('Informe a descrição do produto'),
        valor: Yup.string().required('Informe o valor'),
        largura: Yup.string().required('Informe a largura'),       
        altura: Yup.string().required('Informe a altura'),
        rendimento_kg: Yup.string().required('Informe o redimento Kg')
      });         

      await schema.validate({codigo, nome, category_id, subcategory_id, descricao, altura, comprimento, largura, valor, rendimento_kg}, {
        abortEarly: false
      });

  
      const postData = {          
        nome,
        descricao,
        video_url,
        largura: largura.replace(",","."),
        altura: altura.replace(",","."),
        comprimento: comprimento.replace(",","."),
        valor: valor.replace(",","."),
        rendimento_kg: rendimento_kg ? rendimento_kg.replace(",",".") : '0.00',
        codigo_erp_produto: 0,
        codigo_ncm,
        codigo_extpi: '',
        origem: '',
        ativo,
        destaque,
        codigo,
        colors,
        compositions,
        valor_metro_kg: unidade === 'kg' ? valor_metro_kg : '0.00',
        category_id: category,
        subcategory_id: subcategory,
        unidade,
        peso: peso.replace(",","."),
      };


      if (action === 'update'){
        postData.id = id;
      }
  
      await api.post(`/products`, postData);
     
      history.push('/admin/products');
   
    }
    catch(e){ 

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
  
    }
  },[action, codigo_ncm, category, subcategory, unidade, descricao, colors, compositions, ativo, destaque, altura, largura, comprimento]);

  const loadData = useCallback(async () => {    
    try {

      
      const response = await api.get(`/products/${id}`);
      setCodigo(response.data.codigo);   
      setCodigoErp(response.data.codigo_erp_produto);
      setNome(response.data.nome); 
      setCategory(response.data.category_id);
      setSubcategory(response.data.subcategory_id);
      setDescricao(response.data.descricao);
      setVideoUrl(response.data.video_url);
      setLargura(response.data.largura);
      setAltura(response.data.altura);
      setComprimento(response.data.comprimento);
      setValor(response.data.valor);
      setRendimentoKg(response.data.rendimento_kg);
      setValorMetroKg(response.data.valor_metro_kg);
      setAtivo(response.data.ativo);
      setUnidade(response.data.unidade ? response.data.unidade : 'kg');
      setDestaque(response.data.destaque);
      setPeso(response.data.peso);
      setCodigoNcm(response.data.codigo_ncm);

      const colorsSelected = [];
      response.data.colors.map(c => {
        colorsSelected.push(c.id);
      });
      setColors(colorsSelected);

      const compositionsSelected = [];
      response.data.compositions.map(c => {
        compositionsSelected.push(c.id);
      });
      setCompositions(compositionsSelected);

      setLoaded(true);
      setLoading(false);
      
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      
      history.push("/admin/products");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
        
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do produto</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>

              {loaded}

            {loaded ?
            <>              
            <TextField
                variant="outlined"              
                fullWidth
                id="codigo" 
                label="Código referência do produto"
                name="codigo"
                value={codigo}
                required
                onChange={(e) => setCodigo(e.target.value)}
            
            />   
                              
            <TextField
                variant="outlined"
                margin="normal"       
                fullWidth
                id="nome" 
                label="Nome do produto"
                name="nome"
                value={nome}
                required
                style={{marginTop: 24}}
                onChange={(e) => setNome(e.target.value)}
            
            />      
     
             <TextField
                variant="outlined"
                margin="normal"       
                fullWidth
                id="codigo_ncm" 
                label="Codigo NCM do produto"
                name="codigo_ncm"
                value={codigo_ncm}
                required
                onChange={(e) => setCodigoNcm(e.target.value)}
            
            />      
     

            <Category value={category} handleChangeValue={(value) => setCategory(value)} marginTop={15} />

            <Subcategory value={subcategory} category={category} handleChangeValue={(value) => setSubcategory(value)} marginTop={22} />

          
            <p style={{marginTop: 20}}>Descrição do produto</p>
            <CKEditor
                    editor={ Editor }
                    config={ editorConfiguration }
                    data={descricao}
                    onReady={(editor) => {       
                      editor.editing.view.change((writer) => {
                      writer.setStyle(
                          "height",
                          "400px",
                          editor.editing.view.document.getRoot()
                      );
                      });
                    }}    
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();                      
                      setDescricao(data);
                  } }           
              />       

             <MultipleSelection selected={colors} resource={"products/color"} handleChange={handleChangeColor} label="Selecione as cores" boxcolor marginTop={20} />
            
             <MultipleSelection selected={compositions} resource={"products/composition"} handleChange={handleChangeComposition} label="Selecione as composições" marginTop={20} />
             
             <TextField
                variant="outlined"
                margin="normal"       
                fullWidth
                id="video_url" 
                label="URL Video (You Tube)"
                name="video_url"
                value={video_url}
                onChange={(e) => setVideoUrl(e.target.value)}            
              />      
    

              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 18}}>
                <InputLabel id="category">Unidade venda</InputLabel>             
                <Select 
                        labelId="unidade"
                        label="unidade venda"
                        variant="outlined"                  
                        name="unidade"
                        fullWidth
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}              
                        >    
                        <MenuItem key="kg" value="kg">Kg</MenuItem> 
                        <MenuItem key="mt" value="mt">Metro</MenuItem>
                        <MenuItem key="un" value="un">Unidade</MenuItem> 
                        <MenuItem key="m3" value="m3">Multiplo de 3</MenuItem>                                                                 
                </Select>
              </FormControl>

              <CurrencyTextField
                  style={{marginTop: 25}} 
                  label="Valor"
                  variant="standard"
                  value={valor}
                  currencySymbol="R$"
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valor"
                  onChange={(event, value)=> setValor(value)}
               />

              {unidade && unidade === 'kg' ? 
              <><CurrencyTextField
                style={{marginTop: 20}}
                  label="Rendimento Kg"
                  variant="standard"
                  value={rendimento_kg}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="rendimento_kg"
                  onChange={(event, value)=> setRendimentoKg(value)}
               />      
         
              <TextField
                variant="outlined"
                margin="normal"       
                fullWidth
                id="valor_metro_kg" 
                label="Valor Metro Kg"
                name="valor_metro_kg"
                value={valor_metro_kg}
                disabled                           
              />    
              </> : null}       

              {unidade && (unidade === 'mt' || unidade === 'm3') ? 
              <><CurrencyTextField
              style={{marginTop: 20}}
                label="Peso em 1 Metro"
                variant="standard"
                value={peso}
                currencySymbol=""
                variant="outlined"
                margin="normal"       
                fullWidth
                textAlign="left"              
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator=""
                name="peso"
                onChange={(event, value)=> setPeso(value)}
             />
              </> : null} 
               
              <CurrencyTextField
                style={{marginTop: 20}}
                  label="Largura (cm)"
                  variant="standard"
                  value={largura}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="largura"
                  onChange={(event, value)=> setLargura(value)}
               />   

                
              <CurrencyTextField
                style={{marginTop: 20}}
                  label="Altura (cm)"
                  variant="standard"
                  value={altura}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="altura"
                  onChange={(event, value)=> setAltura(value)}
               />   

                
              {unidade === 'un' ? <CurrencyTextField
                style={{marginTop: 20}}
                  label="Comprimento (cm)"
                  variant="standard"
                  value={comprimento}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="comprimento"
                  onChange={(event, value)=> setComprimento(value)}
               /> : null}


              {unidade && unidade === 'un' ? 
              <><CurrencyTextField
                style={{marginTop: 20}}
                  label="Peso"
                  variant="standard"
                  value={peso}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="peso"
                  onChange={(event, value)=> setPeso(value)}
               />   
              </> : null} 

              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 18}}>
                <InputLabel id="category">Ativo no site?</InputLabel>             
                <Select 
                        labelId="ativo"
                        label="ativo no site"
                        variant="outlined"                  
                        name="ativo"
                        fullWidth
                        value={ativo}
                        onChange={(e) => setAtivo(e.target.value)}              
                        >    
                        <MenuItem key="A1" value={true}>Sim</MenuItem> 
                        <MenuItem key="A2" value={false}>Não</MenuItem>                                                                 
                </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                <InputLabel id="category">Destaque no site?</InputLabel>             
                <Select 
                        labelId="destaque"
                        label="destaque no site"
                        variant="outlined"                  
                        name="destaque"
                        fullWidth
                        value={destaque}
                        onChange={(e) => setDestaque(e.target.value)}              
                        >    
                        <MenuItem key="D1" value={true}>Sim</MenuItem> 
                        <MenuItem key="D2" value={false}>Não</MenuItem>                                                                 
                </Select>
            </FormControl>       
            </> : isLoading ? (
                <Box mt={6}>
                <CircularProgress />
                </Box>               
              ) : null}   
                
              </CardBody>
              {loaded ? <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter> : null}
           </form>

          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
