import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import FileUploader from 'components/FileUploader/FileUploader';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles(styles);

export default function EditCategory() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [removerImagem, setRemoverImagem] = useState(false);
  const [imagem, setImagem] = useState('');
  const [imagem2, setImagem2] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFile2, setSelectedFile2] = useState('');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();    

      setLoading(true);
      
      const name = e.target.name.value;

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),              
      });         

      await schema.validate({name}, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('name', name);

      if (selectedFile){
          formData.append('image_01', selectedFile);
      }

      if (selectedFile2){
        formData.append('image_02', selectedFile2);
      }

      if (removerImagem){
          formData.append('removerImagem', removerImagem);
      }

      if (action === 'update'){
          formData.append('id', id);
      }
 
      await api.post('/categories', formData);

      setLoading(false);
   
      history.push('/admin/categories');
   
    }
    catch(e){ 

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    

      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
  
    }
  },[action, selectedFile, selectedFile2, removerImagem]);

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/categories/${id}`);

      setName(response.data.name);    
      setImagem(response.data.image);
      setImagem2(response.data.image2);

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/categories");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da Categoria</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  /> 

                  {imagem ?
                  <>     
                    <hr />
                       <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/categories/rs/${imagem}`} width="250" height="250" />       
                      
                    <hr />
                  </>:  null}

                  {imagem2 ?
                  <>     
                    <hr />
                       <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/categories/rs/${imagem2}`} width="250" height="250" />       
                                      <hr />
                  </>:  null}

                  {imagem && imagem2 ? <><Checkbox checked={removerImagem} onChange={(event) => setRemoverImagem(!removerImagem)} name="remover" />Remover imagens</> : null}

                  <p>Imagem 01</p>  
                  <FileUploader
                    onFileSelectSuccess={(file) => { 
                            setSelectedFile(file);                     
                            setImagem(null); 
                            setRemoverImagem(false);
                    }}
                    onFileSelectError={({ error }) => addToast(error, {
                      appearance: 'error',
                      autoDismiss: true,
                    })}
                  />

                  <br />

                  <p>Imagem 02</p>
                  <FileUploader
                    onFileSelectSuccess={(file) => { 
                            setSelectedFile2(file);                     
                            setImagem2(null); 
                            setRemoverImagem(false);
                    }}
                    onFileSelectError={({ error }) => addToast(error, {
                      appearance: 'error',
                      autoDismiss: true,
                    })}
                  />
               
                </GridItem>               
              </GridContainer>
             
            </CardBody>
            <CardFooter>
              {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
             <CircularProgress />
            </Box>}
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
