import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import AutoComplete from 'components/AutoComplete/AutoComplete.js';

import FormControl from '@material-ui/core/FormControl';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Estoque() {
  const selectInputRef = useRef();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [itens, setItens] = useState([]);
  const [posicao, setPosicao] = useState();
  const [codigo, setCodigo] = useState('');
  const [produto, setProduto] = useState('');
  const [since, setSince] = useState(new Date(new Date() - (20 * 86400000)));
  const { addToast } = useToasts()

  useEffect(() => {
      getData();
  }, []);

 
  const handleSinceChange = (date) => {
    setSince(date);
  };

  const getProduct = useCallback(async() => {
    setProduto('');
    const response = await api.post("/products/search", {
      filtros: {
       codigo_erp: codigo,
      }
    });

    if (response.data.length > 0){
      setProduto(`${response.data[0].nome} - ${response.data[0].images[0].color.name}`);
    }


  },[codigo]);

  const limparMovimentacao = useCallback(async() => {
     try {

        setLoading(true);     
        const response = await api.delete("/products/stock/delete", {});
        setLoading(false);

        getData();
      }
      catch(e){

        addToast('Ocorreu um erro para atualizar os dados', {
          appearance: 'error',
          autoDismiss: true,
        });           
        setLoading(false);
      }
 
  },[]);

  const getData = useCallback(async () => {

    try {

     setLoading(true); 
  
     const response = await api.get("/products/stock/history", {
       params: {
         product_id: codigo,
         since,
       }
      });

      const itensmov = [];

      if (response.data.posicao){
        setPosicao(response.data.posicao);
      }
      else{
        setPosicao(null);
      }

      if (response.data.movimentacao){
        
        response.data.movimentacao.map(d => {
            itensmov.push({
              data:  new Date(d.created).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              }),
              name: `${d.product.nome} - ${d.color.name}`,
              codigo: d.codigo,
              acao: d.tipo_nome,
              quantidade: d.tipo_nome === "Entrada" || d.tipo_nome === "Substituição" ? "(+) " + d.quantidade : "(-) " + d.quantidade,
              codigosistema: d.product.codigo,
              info: d.info
            })
        })
  
        setItens(itensmov);
      }
     
      setLoading(false);

    }
    catch(e){    
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });   
     

    } 
  },[codigo, since]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',
          onClick: () => alert('Click No')
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/modopreparo/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Movimentação de Estoque</h4>
            <p className={classes.cardCategoryWhite}>
              Histórico de movimentações de estoque
            </p>
          </CardHeader>
          <CardBody>

          <GridContainer>
              <GridItem xs={12} sm={12} md={3}>  
                     <TextField 
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="codigo"                     
                          label="Código ERP"
                          value={codigo}
                          onChange={(event) => setCodigo(event.target.value)}
                          onBlur={(event) => getProduct()}
                      />    
               </GridItem>              
               <GridItem xs={12} sm={12} md={9}>  
                    {produto ?  <TextField 
                          variant="outlined"
                          margin="normal"
                          disabled
                          fullWidth
                          id="produto"                     
                          label="Produto"
                          value={produto}                 
                      /> : null}
               </GridItem>              
        
            <GridItem xs={12} sm={12} md={12}>         
             <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
              <DatePicker style={{marginTop: 15}} label="Movimentacao a partir de" value={since} onChange={handleSinceChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
             </MuiPickersUtilsProvider>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>         
            <Button color="warning" style={{marginTop: 20}} onClick={() => getData()}>Buscar</Button>            
            <Link to="/admin/estoque/new"> <Button color="info" style={{marginTop: 20}}>Movimentar estoque</Button></Link>
            <br />
            <Button color="danger" style={{marginTop: 20}} onClick={() => limparMovimentacao()}>Limpar Movimentação Estoque</Button>
           </GridItem>

           </GridContainer>
          <hr />
           {posicao && itens ? <h4>Posição atual do item <strong>{itens[0]?.name}</strong>: {posicao.quantidade}</h4> : null}
           {itens && itens.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Data', 'Codigo Erp', 'Codigo', 'Produto','Ação', 'Qtde', 'Info']}
              tableColumn={['data', 'codigo', 'codigosistema', 'name', 'acao',  'quantidade', 'info']}
              tableColumnSize={['20%', '15%', '15%', '15%', '15%', '5%', '10%']}
              tableData={itens} 
              resource="estoque"            
              onDelete={(id) => handleDelete(id)}
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhuma movimentação de estoque realizada.</p>
          )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
