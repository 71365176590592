import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Category from 'components/Category/Category.js';
import Subcategory from 'components/Subcategory/Subcategory.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function ConfiguracooesHomePage() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { addToast } = useToasts()
  const history = useHistory();

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true); 

      const data = [];
      const response = await api.get("/opcaohome"); 

      response.data.map(o => {
        data.push({
          id: o.id,
          category: o.category.name,
          subcategory: o.subcategory?.name,
          posicao: o.posicao,
          tipo: o.tipo
        })
      })
      
  
      setLoading(false);
      setData(data);
    }
    catch(e){

      setLoading(false);
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        
    } 
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Configuração Home - Categorias / Subcategorias</h4>
   
          </CardHeader>
          <CardBody> 
                
          <Link to="/admin/opcaohome/new"> <Button color="info" style={{marginTop: 20}}>Adicionar nova configuração</Button></Link>
 
          {data && data.length > 0 ? 
            <Table
                tableHeaderColor="primary"
                tableHead={['Posição', 'Tipo', 'Categoria', 'Subcategoria', '']}
                tableColumn={['posicao', 'tipo', 'category', 'subcategory', 'edit']}
                tableColumnSize={['1%', '20%', '20%', '20%', '1%']}
                tableData={data} 
                resource="opcaohome"                     
            /> : isLoading ? (
              <Box mt={6}>
               <CircularProgress />
              </Box>               
           ) : (           
             <p style={{marginTop: 20}}>Nenhuma configuração cadastrada no momento.</p>
           )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
