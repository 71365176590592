import axios, { AxiosError } from 'axios'

/*
const apiFrete = axios.create({
    baseURL: 'https://sandbox.melhorenvio.com.br',
    headers: {
       Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQ3NzhkZDE5MmY5MzZiNjllZmE2Y2RhZDUxY2M2MzVmZjFlY2Q1MzM2YzZmNzViZmQwMzdmODg5YzJiYTUwNWYwMjg0OTcwMmMxYjVlYjE3In0.eyJhdWQiOiI5NTYiLCJqdGkiOiI0Nzc4ZGQxOTJmOTM2YjY5ZWZhNmNkYWQ1MWNjNjM1ZmYxZWNkNTMzNmM2Zjc1YmZkMDM3Zjg4OWMyYmE1MDVmMDI4NDk3MDJjMWI1ZWIxNyIsImlhdCI6MTYzMjUxMTM0NSwibmJmIjoxNjMyNTExMzQ1LCJleHAiOjE2NjQwNDczNDUsInN1YiI6Ijg4ZTUwNmYzLWM1MTQtNDFjMy1hODZkLTg2N2RmOTQzOGUwYyIsInNjb3BlcyI6WyJjYXJ0LXJlYWQiLCJjYXJ0LXdyaXRlIiwic2hpcHBpbmctY2FsY3VsYXRlIiwic2hpcHBpbmctY2hlY2tvdXQiLCJzaGlwcGluZy1nZW5lcmF0ZSIsInNoaXBwaW5nLXByZXZpZXciLCJzaGlwcGluZy1wcmludCIsImVjb21tZXJjZS1zaGlwcGluZyJdfQ.L1XJu_cO80etdoZ-oLfwqtUS1A-LnS8SG7z41zMwGFo_7udJ9h5SVPiKZGMr2sm4njLHDCaci6Qfi-62aoq_Dc922MiFpKQoPlbZarw1eN13KY8aQM-yPVIIVhAih1yMBbnO2VvIAELAqfBRsleaXWudOBy8_X1v2Xb8-NX2c07N6BTzcpX3sY-NO5AJ3CpIsjwfF6EqjoBvigdVeTXg4yDWVehlULeqr8_AtvZgn7zyaHmk76OTrxKU2xAxSZwUwVFjXX4mRxzZoXGZKalWrPIocHDFpge9Z0BdqOTqr6HRgTsebAIA_nFvatx6lnen2lnJ3Be98zYQQs2sL-ay8qC0Bg3LP0XO_IyAfmH1lCPRB-bqw2b0OX6xmiTo7_PxpNWTktu_ReVouzPhHvGPC4SsrTYjn-xNyrdxHTbkxmj27hkAwJOyyMPjyZ1yMEPfpeXQrzg-YShl5CNEm-EylqMAMFhaKxGiPeyqmxmYtmddlCQVcazVFPCcmxK8Af88HD29nVgw0oQMy0vl30eaIYBuuCjMxGG5nKoFUwXLgKxnpauyWk5PuHJ_munkcpeQesO4KmUuVkJAD55DEXtmh28B6wf7uWbyp7T1HTjuvP6qlwh3oJyCuVWCH4QDUzLT7F_EhGejoaQf3OsV-TRqoT3_kqdFQluOBRUHwzzcFqU`
    }
});
*/

const apiFrete = axios.create({
    baseURL: 'https://melhorenvio.com.br',
    headers: {
       Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjhhNWVhODQ3YjUxNmQ2ZGRlYzY2OTkwNzVhMGU2YWJlOWNmNTU1ZmM0NDhlZjllMjhlZDJlNjgyYWQ3NzgyNzBlNzkzNGYyNDI4MmUzYmM5In0.eyJhdWQiOiIxIiwianRpIjoiOGE1ZWE4NDdiNTE2ZDZkZGVjNjY5OTA3NWEwZTZhYmU5Y2Y1NTVmYzQ0OGVmOWUyOGVkMmU2ODJhZDc3ODI3MGU3OTM0ZjI0MjgyZTNiYzkiLCJpYXQiOjE2Njc1OTI3NTYsIm5iZiI6MTY2NzU5Mjc1NiwiZXhwIjoxNjk5MTI4NzU2LCJzdWIiOiI4MjlhOTdjYy1hMTlmLTQzZTYtYjJlYi04ODFjYmEwNDFjYmMiLCJzY29wZXMiOlsiY2FydC1yZWFkIiwiY2FydC13cml0ZSIsImNvbXBhbmllcy1yZWFkIiwiY291cG9ucy13cml0ZSIsIm5vdGlmaWNhdGlvbnMtcmVhZCIsIm9yZGVycy1yZWFkIiwicHJvZHVjdHMtcmVhZCIsInByb2R1Y3RzLWRlc3Ryb3kiLCJwcm9kdWN0cy13cml0ZSIsInB1cmNoYXNlcy1yZWFkIiwic2hpcHBpbmctY2FsY3VsYXRlIiwic2hpcHBpbmctY2FuY2VsIiwic2hpcHBpbmctY2hlY2tvdXQiLCJzaGlwcGluZy1jb21wYW5pZXMiLCJzaGlwcGluZy1nZW5lcmF0ZSIsInNoaXBwaW5nLXByZXZpZXciLCJzaGlwcGluZy1wcmludCIsInNoaXBwaW5nLXNoYXJlIiwic2hpcHBpbmctdHJhY2tpbmciXX0.pbK9PZ9_3aaQF4XalxxaiIpxCX7tmgKj4_9lJ0jcQTMUJ23SHN93Bie6K8Dblq4a8xUvIoydZEAN-Zkl2oFRRvZgqEpdlE0cXDsyct5BNJf4jZL_03SLP4AkWAE_95Joo24FaBiWRVFHCrTQiNi51cYDcHrzO7Hhnu1fthPw4ON4JGG6fDUNaTBA2W5rSHiRmZIb_zx8-_MkhDj8hYxnuaFsxSo_oC6sW4vrU7R1Yk9NBYtbaNYV2wUY2kiV4G7F65qUF_6sVLHdY8VgfU-K5_72LnvSksbfIqyGjn4KGMujktsivZDmFxs51QH80O_za1Om0gMI9C8sSj3C6KqvSX2JsLdUOKSZl_x_YmeIbC_XUeKQE838dKcIAtekxQ3YKvEXNK02yTOPj6KZpRLqcNO8KiSzm8ZOXE_Tdi9FwZpGjfloVnoblGsQNE8J7msiDTUMzk5q5EUVOlO3G8t7ngsZt0Zj3e7EpqwdcaeJMbnVOkzpyT8f74ehoFDHFWVDwYAWrBUWQ1ea2A1rtEOxLk6AWTRC1IgxgE7MHgn2UNuIOZ_6rahRNR2flxE83-cf-Aq9MZd0OZzJNopDxVjoQhqoFkjokK0-LmqRvuZUgiYHgKDCSmG35gLi65rOKvYZeYRnSZbyOPvKUkkqu1Y6RuPJKP6AxTjC2CPho96GwkE`
    }
});
  
 
export default apiFrete;