import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';

import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditCustomer() {
  const [action, setAction] = useState('create');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    loadData(id);
  },[]);
  

  const loadData = useCallback(async () => {    
    try {
      setLoading(true);
      const response = await api.get(`/customers/${id}`);
      setData(response.data);  
      setLoading(false);
      

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/customers");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>         
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do cliente</h4>            
            </CardHeader>
            <CardBody>

                {loading ? (
                  <Box mt={6}>
                  <CircularProgress />
                  </Box>               
                ) : (      
                    <>     
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Nome"
                            name="name"
                            disabled 
                            value={data.name}                     
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer> 


                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="tipo"
                            label="Tipo Cliente"
                            name="tipo"
                            disabled 
                            value={data.type === 'PF' ? 'Pessoa Física' : 'Pessoa Jurídica'}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>


                    {data.type === 'PJ' ? <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="inscricao"
                            label="Inscrição Estadual"
                            name="inscricao"
                            disabled 
                            value={data.inscricao}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer> : null}

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            disabled 
                            value={data.email}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="telefone1"
                            label="Telefone 1"
                            name="telefone"
                            disabled 
                            value={data.tel_01}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="telefone2"
                            label="Telefone 2"
                            name="telefone"
                            disabled 
                            value={data.tel_02}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="telefone3"
                            label="Telefone 3"
                            name="telefone"
                            disabled 
                            value={data.tel_03}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="cadastrado"
                            label="Cadastrado em"
                            name="cadastrado"
                            disabled 
                            value={new Date(data.created_at).toLocaleDateString('pt-br', {
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric'
                             })}                      
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer>


                   
                    </>
                   )}
                                
            </CardBody>
            <CardFooter>
            <Link to="/admin/customers"><Button color="primary" type="button">Voltar</Button></Link>
            </CardFooter>
          
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
