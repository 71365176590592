import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

// core components

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditLargura() {
  const [action, setAction] = useState('create');
  const [isLoading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [valorInicial, setValorInicial] = useState();
  const [valorFinal, setValorFinal] = useState();
 
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
    else{
      setLoaded(true);
      setLoading(false);
    }
  },[action]);


  const schema = Yup.object().shape({   
    valorInicial: Yup.string().required('Informe o valor inicial'),
    valorFinal: Yup.string().required('Informe o valor final'),
  });  


  const handleSave = useCallback(async (e) => {

    try {  

      e.preventDefault();

      const valor_inicial = e.target.valor_inicial.value;
      const valor_final = e.target.valor_final.value;
      const valorInicial = valor_inicial.replace(",",".");
      const valorFinal = valor_final.replace(",",".");
      
      if (Number(valorInicial) > Number(valorFinal)){
        addToast('Valores inválidos !', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;
      }

      await schema.validate({valorInicial, valorFinal}, {
        abortEarly: false,
      });
           
      const postData = {          
        valor_inicial: valorInicial,
        valor_final: valorFinal
      };


      if (action === 'update'){
        postData.id = id;
      } 
 
      await api.post(`/larguras`, postData);
     
      history.push('/admin/larguras');
   
    }
    catch(e){ 


      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
      history.push("/admin/larguras");
  
    }
  },[action, valorInicial, valorFinal]);

  const loadData = useCallback(async () => {   

    try {
      
      const response = await api.get(`/larguras/${id}`);      
      setValorInicial(response.data.valor_inicial);
      setValorFinal(response.data.valor_final);   
      setLoaded(true);
      setLoading(false);
      
    }
    catch(e){
  

      addToast('Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      
      history.push("/admin/larguras");

    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card> 
        
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Filtro - Largura</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>

            {loaded ?
            <>           
              <CurrencyTextField
                  style={{marginTop: 25}} 
                  label="De"
                  variant="standard"
                  value={valorInicial}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valor_inicial"
                  onChange={(event, value)=> setValorInicial(value)}
               />

              <CurrencyTextField
                  style={{marginTop: 25}} 
                  label="Ate"
                  variant="standard"
                  value={valorFinal}
                  currencySymbol=""
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valor_final"
                  onChange={(event, value)=> setValorFinal(value)}
               />
               
            </> : isLoading ? (
                <Box mt={6}>
                <CircularProgress />
                </Box>               
              ) : null}   
                
              </CardBody>
              {loaded ? <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter> : null}
           </form>

          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
